import { Button } from "antd";

import cookiesIcon from "data/icons/cookies.svg";
import { useAppDispatch, useAppSelector } from "store";
import { getUserSelector } from "store/user/user.selector";
import { getUserData } from "store/user/user.thunks";
import api from "services/api.service";

import "./Cookies.scss";

const Cookies = () => {
	const dispatch = useAppDispatch();
	const { user } = useAppSelector(getUserSelector);

	const onAccept = async () => {
		try {
			await api.setUserCookies("accept");
		} catch (e) {
			console.log(e);
		}

		dispatch(getUserData(() => {}));
	};

	const onReject = async () => {
		try {
			await api.setUserCookies("reject");
		} catch (e) {
			console.log(e);
		}

		dispatch(getUserData(() => {}));
	};

	if (!user?.require_cookies_action) {
		return null;
	}

	return (
		<div className="cookies">
			<img
				alt=""
				src={ cookiesIcon }
			/>

			<div className="cookies-content">
				<div className="cookies-content-title">
					We value your privacy
				</div>

				<div>
					We use cookies to give you the best experience on our platform.
					By clicking “Accept All”, you consent to our use of cookies.
				</div>
			</div>

			<div className="cookies-buttons">
				<Button onClick={ onReject }>
					Reject All
				</Button>

				<Button
					onClick={ onAccept }
					type="primary"
				>
					Accept All
				</Button>
			</div>
		</div>
	);
};

export default Cookies;
