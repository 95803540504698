import { useState } from "react";

import { message } from "antd";

import api from "services/api.service";

type TContractorList = {
  value: string;
  label: string;
  phone_number?: string;
  email?: string;
  official_name?: string;
  has_account?: boolean;
  is_my_org?: boolean;
}[];

export const useContractors = (limit = 20) => {
  const [ contractors, setContractors ] = useState<TContractorList>([]);

  const getContractors = async (search: string) => {
    try {
      const response = await api.getContractors({ search }, limit, 0);

      setContractors([
        ...response.data.results.map((res) => ({
          value: res.id.toString(),
          label: res.name,
          phone_number: res.phone_number,
          email: res.default_email,
          official_name: res.official_name,
          has_account: res.has_account,
          is_my_org: res.is_my_org,
        })),
      ]);
    } catch (e: any) {
      message.error(e.response.data.error || e.error || "Something went wrong!");
    }
  };

  return {
    contractors,
    getContractors,
  };
};
