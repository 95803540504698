import { FC, useState } from "react";

import { Header } from "antd/es/layout/layout";
import { Link, useLocation } from "react-router-dom";
import { Button, Modal } from "antd";

import { RouteLinks } from "services/router.service";
import loginBlueIcon from "data/icons/login-blue.svg";
import { useAppSelector } from "store";
import { getUserSelector } from "store/user/user.selector";
import { authorizedPages } from "services/data.service";
import { ContactUsModal, LoginForm, Notifications, UserMenu } from "components/common";
import { getContractsSelector } from "store/contracts/contracts.selector";
import { CreateCaseModal } from "components/cases";

import "./TopBar.scss";

const TopBar: FC = () => {
  const loc = useLocation();

  const { isAuthorized, isMobile } = useAppSelector(getUserSelector);
  const { currentContract } = useAppSelector(getContractsSelector);

  const [ isLoginOpen, setIsLoginOpen ] = useState(false);
  const [ isContactUsOpen, setIsContactUsOpen ] = useState(false);

  const extraLinks = [
    {
      value: RouteLinks.PROTECTED.DOWNLOAD_ROUTES.ADD_FILE,
      title: "Add File",
    },
    {
      value: RouteLinks.PROTECTED.DOWNLOAD_ROUTES.FILE,
      title: "File",
    },
    {
      value: RouteLinks.PROTECTED.CASES.CASE,
      title: "Case",
    },
    {
      value: RouteLinks.PROTECTED.CONTRACTS_ROUTES.CONTRACT,
      title: currentContract ? currentContract?.identifier : "Loading...",
    },
    {
      value: RouteLinks.PROTECTED.CONTRACTORS.NEW,
      title: "Add contractor",
    },
    {
      value: RouteLinks.PROTECTED.CONTRACTS_ROUTES.INVITATION,
      title: "Invitations",
    },
  ]

  return (
    <Header className={ `app-header ${ isMobile ? "mobile" : "" }` }>
      { isAuthorized ? (
        <>
          { !isMobile ? (
            <p className="page-title page-title-contract">
              {(loc.pathname.includes(RouteLinks.PROTECTED.CONTRACTS_ROUTES.CONTRACT) && currentContract) ? (
                <>
                  <Link
                    className="page-title-link"
                    to={ RouteLinks.PROTECTED.CONTRACTS_ROUTES.MAIN }
                  >
                    Contracts
                  </Link>

                  <span className="page-title-divider">/</span>

                  {currentContract.identifier}
                </>
              ) : (
                <span className="page-title-label">
                  {
                    authorizedPages.find((page) => page.link === loc.pathname)?.label
                    || extraLinks.find((link) => loc.pathname.indexOf(link.value) !== -1)?.title
                  }
                </span>
              )}
            </p>
          ) : (
            <>
              <UserMenu/>
              <Notifications />
            </>
          ) }

          <div className="create-case-wrapper">
            <CreateCaseModal />
          </div>

          { !isMobile && (
            <>
              <Notifications />
              <UserMenu />
            </>
          ) }
        </>
        ) : (
        <>
          { (
            loc.pathname !== RouteLinks.AUTH &&
            loc.pathname !== RouteLinks.INVITE_COMPLETE
          ) && (
            <a
              className="login-button"
              style={ {
                margin: "10px 20px 0 auto",
              } }
            >
              <Button
                type="default"
                onClick={ () => setIsLoginOpen(true) }
              >
                <img src={ loginBlueIcon } alt=""/>
                Log in
              </Button>
            </a>
          ) }
        </>
      ) }

      <ContactUsModal
        isContactUsOpen={ isContactUsOpen }
        setIsContactUsOpen={ setIsContactUsOpen }
      />

      <Modal
        open={ isLoginOpen }
        onCancel={ () => setIsLoginOpen(false) }
        footer={ null }
        width={ 440 }
      >
        { isLoginOpen && (
          <div style={ { padding: "24px 16px 8px 16px" } }>
            <LoginForm onSuccess={ () => setIsLoginOpen(false) }/>
          </div>
        ) }
      </Modal>
    </Header>
  )
}

export default TopBar;
