import { useEffect } from "react";

import { Layout } from "antd";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Content } from "antd/es/layout/layout";

import {
  AuthorizationPage,
  WelcomePage,
  ResetPasswordPage,
  RegistrationPage,
  CasesPage,
  DownloadsPage,
  ListEquipmentPage,
  ContractsPage,
  CurrentContractPage,
  ContractNewPage,
  NotFoundPage,
  ResetPasswordConfirmPage,
  StoragePage,
  CurrentCasePage,
  ProductsPage,
  MobileOopsPage,
  ContractorsPage,
  SettingsPage,
  LandingPage,
  LandingPageV2,
  InviteCompletePage,
  ContractInvitationsPage,
} from "pages";
import { TopBar, NavBar } from "components";
import { Cookies } from "components/common";
import { RouteLinks } from "services/router.service";
import { useAppDispatch, useAppSelector } from "store";
import { getUserSelector } from "store/user/user.selector";
import { FileForm, DownloadCards, DownloadsFooter, DownloadsHeader, DownloadsTable } from "components/downloads";
import { ContractorForm } from "components/contractors";
import { getDownloadsSelector } from "store/downloads/downloads.selector";
import { parseJwt } from "services/data.service";
import { authorizeUserSuccess, setIsAdmin, setIsMobile } from "store/user/user.actions";
import { getOrganizationData, getUserData } from "store/user/user.thunks";

import "./App.scss";

function App() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loc = useLocation();
  const { isAuthorized, isMobile, isAdmin } = useAppSelector(getUserSelector);
  const { dataDisplayType } = useAppSelector(getDownloadsSelector);

  const bgNone = loc.pathname.includes(RouteLinks.INVITE_ORGANIZATION_COMPLETE) && !isAuthorized;

  useEffect(() => {
    dispatch(setIsMobile(window.outerWidth <= 480))

    const handleSetIsMobile = () => {
      dispatch(setIsMobile(window.outerWidth <= 480))
    }

    window.addEventListener("resize", handleSetIsMobile)

    return () => {
      window.removeEventListener("resize", handleSetIsMobile);
    }
  }, [])

  useEffect(() => {
    const token = localStorage.getItem("AUTH_TOKEN");
    if (token !== null && !isAuthorized) {
      const infoFromToken = parseJwt(token);
      if (infoFromToken.exp > new Date().getTime()/1000) {
        dispatch(authorizeUserSuccess());
        dispatch(setIsAdmin(infoFromToken.type == "service"));
      } else {
        localStorage.removeItem("AUTH_TOKEN");
      }
    }

    if (token !== null && isAuthorized) {
      const infoFromToken = parseJwt(token);
      dispatch(setIsAdmin(infoFromToken.type));
      loc.pathname === "/" && navigate(RouteLinks.PROTECTED.CASES.MAIN);

      dispatch(getUserData(() => navigate(RouteLinks.MAIN)));
      dispatch(getOrganizationData());
      // (isAdmin || infoFromToken.type == "service") && dispatch(getNewClientsCount()); TODO: revert?
    }
  }, [ isAuthorized ])

  return (
    <Layout
      className={ `App ${
        isAuthorized ? "authenticated" : "" } ${
        isMobile ? "mobile" : "" } ${
        loc.pathname === RouteLinks.MAIN_V2 ? "bg" : "" } ${
        bgNone ? "bgNone" : ""
      }`
      }
    >
      { isAuthorized && !isMobile && <NavBar /> }

      <Layout
        style={ isAuthorized
          ? { background: "none" }
          : { background: "none", maxWidth: "1920px", margin: "0 auto", width: "100%" } }
      >
        {!(
          loc.pathname === RouteLinks.MAIN_V2
          || loc.pathname === RouteLinks.MAIN
          || (loc.pathname === RouteLinks.INVITE_ORGANIZATION_COMPLETE && !isAuthorized)
        ) && (
          <TopBar />
        )}

        <Content className={ `App-inner-content ${ bgNone ? "bgNone" : "" }` }>
          <Cookies />

          <Routes>
            { isAuthorized ? (
              <>
                <Route path={ RouteLinks.PROTECTED.LIST_EQUIPMENT } element={ isMobile ? <MobileOopsPage /> : <ListEquipmentPage /> } />
                <Route path={ RouteLinks.PROTECTED.CASES.MAIN } element={ isMobile ? <MobileOopsPage /> : <CasesPage /> } />
                <Route path={ RouteLinks.PROTECTED.CASES.CASE + "/:caseId" } element={ <CurrentCasePage /> } />
                <Route path={ RouteLinks.PROTECTED.CONTRACTS_ROUTES.MAIN } element={ isMobile ? <MobileOopsPage /> : <ContractsPage /> } />
                <Route path={ RouteLinks.PROTECTED.CONTRACTS_ROUTES.CONTRACT + "/:contractId" } element={ isMobile ? <MobileOopsPage /> : <CurrentContractPage /> } />
                <Route path={ RouteLinks.PROTECTED.CONTRACTS_ROUTES.CONTRACT + "/:contractId" } element={ isMobile ? <MobileOopsPage /> : <CurrentContractPage /> } />
                <Route path={ RouteLinks.PROTECTED.CONTRACTS_ROUTES.NEW } element={ isMobile ? <MobileOopsPage /> : <ContractNewPage /> } />
                <Route
                  path={ RouteLinks.PROTECTED.CONTRACTS_ROUTES.INVITATION }
                  element={ isMobile ? <MobileOopsPage /> : <ContractInvitationsPage /> }
                />
                <Route path={ RouteLinks.PROTECTED.DOWNLOADS } element={ isMobile ? <MobileOopsPage /> : <DownloadsPage /> }>
                  <Route
                    path={ RouteLinks.PROTECTED.DOWNLOAD_ROUTES.MAIN }
                    element={ isMobile ? <MobileOopsPage /> :
                      <>
                        <DownloadsHeader />
                        { dataDisplayType === "table" ? <DownloadsTable /> : <DownloadCards /> }
                        <DownloadsFooter />
                      </>
                    }
                  />
                  <Route
                    path={ RouteLinks.PROTECTED.DOWNLOAD_ROUTES.ADD_FILE }
                    element={ isMobile ? <MobileOopsPage /> : <FileForm /> }
                  />
                  <Route
                    path={ RouteLinks.PROTECTED.DOWNLOAD_ROUTES.FILE + "/:fileId" }
                    element={ isMobile ? <MobileOopsPage /> : <FileForm /> }
                  />
                </Route>
                { isAdmin && (
                  <>
                    <Route
                      path={ RouteLinks.PROTECTED.SETTINGS }
                      element={ isMobile ? <MobileOopsPage /> : <SettingsPage /> }
                    />
                    <Route path={ RouteLinks.PROTECTED.STORAGE } element={ isMobile ? <MobileOopsPage /> : <StoragePage /> } />
                    <Route path={ RouteLinks.PROTECTED.PRODUCTS } element={ isMobile ? <MobileOopsPage /> : <ProductsPage /> } />
                    <Route
                      path={ RouteLinks.PROTECTED.CONTRACTORS.LIST }
                      element={ isMobile ? <MobileOopsPage /> : <ContractorsPage /> }
                    />
                    <Route
                      path={ RouteLinks.PROTECTED.CONTRACTORS.LIST + "/:contractorId" }
                      element={ isMobile ? <MobileOopsPage /> : <ContractorForm /> }
                    />
                    <Route
                      path={ RouteLinks.PROTECTED.CONTRACTORS.NEW }
                      element={ isMobile ? <MobileOopsPage /> : <ContractorForm /> }
                    />
                    <Route path={ RouteLinks.INVITE_ORGANIZATION_COMPLETE } element={ <InviteCompletePage /> } />
                  </>
                ) }
              </>
            ) : (
              <>
                <Route path={ RouteLinks.AUTH } element={ <AuthorizationPage /> } />
                <Route path={ RouteLinks.MAIN } element={ <LandingPage /> } />
                <Route path={ RouteLinks.MAIN_V2 } element={ <LandingPageV2 /> } />
                <Route path={ RouteLinks.SUPPORT } element={ <WelcomePage /> } />
                <Route path={ RouteLinks.RESET_PASSWORD } element={ <ResetPasswordPage /> } />
                <Route path={ RouteLinks.RESET_PASSWORD_CONFIRM } element={ <ResetPasswordConfirmPage /> } />
                <Route path={ RouteLinks.INVITE_ORGANIZATION_COMPLETE } element={ <InviteCompletePage /> } />
              </>
            ) }
            <Route path={ RouteLinks.INVITE_COMPLETE } element={ <RegistrationPage /> } />
            <Route path="*" element={ <NotFoundPage /> } />
          </Routes>
        </Content>
      </Layout>
      { isAuthorized && isMobile && <NavBar /> }
    </Layout>
  );
}

export default App;
