import { useEffect, useState } from "react";

import { ColumnsType } from "antd/es/table";
import { Form, Table, Tag, Tooltip } from "antd";
import dayjs from "dayjs";

import "components/cases/CasesTable/CasesTable.scss";
import { useAppDispatch, useAppSelector } from "store";
import { Loading } from "components/common";
import { CreateCaseModal } from "components/cases";
import { ContractEquipmentsTableEdit } from "components/contracts";
import { IContractEquipment } from "store/contracts/contracts.types";
import { getContractsSelector } from "store/contracts/contracts.selector";
import { updateContractEquipmentsSort } from "store/contracts/contracts.actions";
import { getContractEquipments, getExtraContractEquipments } from "store/contracts/contracts.thunks";
import { extraLimit } from "services/data.service";
import api from "services/api.service";

import "./ContractEquipmentsTable.scss";

interface IProps {
  contractEdit: boolean;
  equipmentsForm: any;
}

const ContractEquipmentsTable = ({ contractEdit, equipmentsForm }: IProps) => {
  const {
    isEquipmentsLoading,
    equipments,
    equipmentsPagination,
    equipmentsSort,
    isExtraEquipmentsLoading,
  } = useAppSelector(getContractsSelector);
  const [ form ] = Form.useForm();
  const dispatch = useAppDispatch();

  const [ expandedRowKey, setExpandedRowKey ] = useState<number | null>(null);
  const [ slaCi, setSlaCi ] = useState<{ value: number, label: string }[]>();
  const [ updateState, setUpdateState ] = useState(false);

  const equipmentList =
    (equipments.length >= extraLimit || ((equipmentsPagination.page - 1) * extraLimit + equipments.length) === equipmentsPagination.count)
    ? equipments : [ ...equipments, { serial_number: "add_more" } as IContractEquipment ];

  const initialValues = {
    equipment: equipmentList.map(contractEquipment => ({
      ...contractEquipment,
      product_number: contractEquipment?.product?.product_number,
      description: contractEquipment?.product?.description,
      sla_ci: { value: contractEquipment?.sla_ci?.id, label: contractEquipment?.sla_ci?.marker },
      date_begin: contractEquipment.date_begin ? dayjs(contractEquipment.date_begin) : null,
      date_end:  contractEquipment.date_end ? dayjs(contractEquipment.date_end) : null,
    })),
  };

  const getSlaCi = async () => {
    const response = await api.getSlaCi();

    if (response.data.results) {
      setSlaCi(response.data.results.map((el) => ({ value: el.id, label: el.marker })));
    }
  };

  useEffect(() => setUpdateState(!updateState), [ isEquipmentsLoading ]);

  useEffect(() => {
    getSlaCi();
  }, []);

  useEffect(() => {
    if (equipments) {
      form.setFieldsValue(initialValues);
    }
  }, [ equipments ]);

  const columns: ColumnsType<IContractEquipment> = [
    {
      title: "",
      dataIndex: "number",
      key: "number",
      width: 40,
      render: (value, item, index) => (
        <p className="equipment-number">
          {index + 1 + (equipmentsPagination.page - 1) * equipmentsPagination.rowsPerPage}.
        </p>
      ),
      // render: (value, item, index) => item.serial_number === "add_more"
      //   ? (
      //     <Button
      //       type="text"
      //       onClick={ () => dispatch(getExtraContractEquipments()) }
      //       disabled={ isExtraEquipmentsLoading }
      //     >
      //       + Show more
      //     </Button>
      //   ) : (
      //     <p className="equipment-number">
      //       { index + 1 + (equipmentsPagination.page - 1) * equipmentsPagination.rowsPerPage }.
      //     </p>
      //   ),
      // onCell: (item) => ({
      //   colSpan: item.serial_number === "add_more" ? 7 : 1,
      //   style: { textAlign: item.serial_number === "add_more" ? "center" : "initial" },
      // }),
    },
    {
      title: "Product number",
      dataIndex: "product_number",
      key: "product_number",
      width: 150,
      render: (value, item) => item.serial_number === "add_more" ? null : <p className="active">{ item.product.product_number }</p>,
      sortOrder: equipmentsSort.column === "product_number" ? equipmentsSort.direction : undefined,
      sorter: equipmentsSort.column === "product_number",
      showSorterTooltip: false,
      onHeaderCell: () => ({
        id: "contract-equipments-header-cell",
        onClick: () => {
          dispatch(updateContractEquipmentsSort({
            column: "product_number",
            direction: equipmentsSort.column === "product_number" ? equipmentsSort.direction === "ascend" ? "descend" : "ascend" : "ascend",
          }))
          dispatch(getContractEquipments())
        },
      }),
      onCell: (item) => ({
        colSpan: item.serial_number === "add_more" ? 0 : 1,
      }),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 190,
      render: (value, item) => item.serial_number === "add_more" ? null : <Tooltip title={ item.product.description }><p className="active thin">{ item.product.description }</p></Tooltip>,
      sortOrder: equipmentsSort.column === "description" ? equipmentsSort.direction : undefined,
      sorter: equipmentsSort.column === "description",
      showSorterTooltip: false,
      className: `description-table-cell`,
      onHeaderCell: () => ({
        onClick: () => {
          dispatch(updateContractEquipmentsSort({
            column: "description",
            direction: equipmentsSort.column === "description" ? equipmentsSort.direction === "ascend" ? "descend" : "ascend" : "ascend",
          }))
          dispatch(getContractEquipments())
        },
      }),
      onCell: (item) => ({
        colSpan: item.serial_number === "add_more" ? 0 : 1,
      }),
    },
    {
      title: "Serial Number",
      dataIndex: "serial_number",
      key: "serial_number",
      width: 190,
      className: `description-table-cell serial-number`,
      render: (value, item) => item.serial_number === "add_more" ? null : <Tooltip title={ value }><p className="active thin">{ value }</p></Tooltip>,
      sortOrder: equipmentsSort.column === "serial_number" ? equipmentsSort.direction : undefined,
      sorter: equipmentsSort.column === "serial_number",
      showSorterTooltip: false,
      onHeaderCell: () => ({
        onClick: () => {
          dispatch(updateContractEquipmentsSort({
            column: "serial_number",
            direction: equipmentsSort.column === "serial_number" ? equipmentsSort.direction === "ascend" ? "descend" : "ascend" : "ascend",
          }))
          dispatch(getContractEquipments())
        },
      }),
      onCell: (item) => ({
        colSpan: item.serial_number === "add_more" ? 0 : 1,
      }),
    },
    {
      title: "Start Date",
      dataIndex: "date_begin",
      key: "date_begin",
      sortOrder: equipmentsSort.column === "date_begin" ? equipmentsSort.direction : undefined,
      sorter: equipmentsSort.column === "date_begin",
      showSorterTooltip: false,
      width: 190,
      onHeaderCell: () => ({
        onClick: () => {
          dispatch(updateContractEquipmentsSort({
            column: "date_begin",
            direction: equipmentsSort.column === "date_begin" ? equipmentsSort.direction === "ascend" ? "descend" : "ascend" : "ascend",
          }))
          dispatch(getContractEquipments())
        },
      }),
      onCell: (item) => ({
        colSpan: item.serial_number === "add_more" ? 0 : 1,
        style: { minWidth: "110px" },
      }),
    },
    {
      title: "End Date",
      dataIndex: "date_end",
      key: "date_end",
      sortOrder: equipmentsSort.column === "date_end" ? equipmentsSort.direction : undefined,
      sorter: equipmentsSort.column === "date_end",
      showSorterTooltip: false,
      width: 190,
      onHeaderCell: () => ({
        onClick: () => {
          dispatch(updateContractEquipmentsSort({
            column: "date_end",
            direction: equipmentsSort.column === "date_end" ? equipmentsSort.direction === "ascend" ? "descend" : "ascend" : "ascend",
          }))
          dispatch(getContractEquipments())
        },
      }),
      onCell: (item) => ({
        colSpan: item.serial_number === "add_more" ? 0 : 1,
        style: { minWidth: "110px" },
      }),
    },
    {
      title: "Support Type",
      dataIndex: "sla_ci",
      key: "sla_ci",
      width: 130,
      render: (value, item) => (
        item.sla_ci ? (
          <Tooltip title={ item.sla_ci?.name }>
            <div style={ { display: "flex" } }>
              <Tag>
                { item.sla_ci?.marker }
              </Tag>
            </div>
          </Tooltip>
        ) : <div style={ { textAlign: "center", width: "70px" } }>{"-//-"}</div>
      ),
    },
  ]

  useEffect(() => {
    const table = document.getElementById("contract-equipment-table");
    if (table) {
      const body: any = table.getElementsByClassName("ant-table-body");

      if (body.length > 0) {
        body[ 0 ].onscroll = (e: any) => {
          const tbody: any = table.getElementsByClassName("ant-table-tbody");
          if (tbody.length > 0) {
            if (tbody[ 0 ].offsetHeight === e.target.scrollTop + e.target.offsetHeight) {
              dispatch(getExtraContractEquipments());
            }
          }
        };
      }
    }
  }, [ isEquipmentsLoading ])

  if (contractEdit) {
    return (
      <ContractEquipmentsTableEdit
        equipmentList={ equipmentList }
        slaCiOptions={ slaCi }
        equipmentsForm={ equipmentsForm }
      />
    )
  }

  return (
    <Loading
      isLoading={ isEquipmentsLoading }
      isColored
      isFullWidth
      height={ 200 }
    >
      <Table
        className="cases-table slim-table"
        id="contract-equipment-table"
        dataSource={ equipmentList }
        columns={ columns }
        pagination={ false }
        expandable={ {
          expandRowByClick: true,
          expandedRowRender: (item) => (
            <div className="expanded-menu">
              <CreateCaseModal
                id={ item.id }
                serial_number={ item.serial_number }
                size="small"
              />
            </div>
          ),
          onExpand: (isExpanded, item) => setExpandedRowKey(isExpanded ? item.id : null),
          showExpandColumn: false,
          expandedRowKeys: expandedRowKey !== null ? [ expandedRowKey ] : [],
        } }
        scroll={ { y: window.innerHeight - 303 - (document.getElementById("contract-equipments-header-cell")?.offsetHeight || 0) } }
        tableLayout="auto"
        rowKey={ (item) => item.id || "" }
      />
    </Loading>
  )
}

export default ContractEquipmentsTable;
