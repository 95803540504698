import { SetStateAction, useEffect, useState } from "react";

import { Button, Input, Modal } from "antd";

import api from "services/api.service";
import editBlue from "data/icons/edit-blue.svg";

import "./ContractInfoSlaModal.scss";

interface IProps {
  contractId: number;
  slaId: number | null;
  isSlaModalOpen: boolean;
  setSlaModalOpen: (open: boolean) => void;
  setSlaIdNew: (value: number) => void;
}

const ContractInfoSlaModal = ({ contractId, slaId, isSlaModalOpen, setSlaModalOpen, setSlaIdNew }: IProps) => {
  const [ slaDescription, setSlaDescription ] = useState<string>();
  const [ isEdit, setIsEdit ] = useState<boolean>(false);
  const [ isConfirm, setIsConfirm ] = useState<boolean>(false);

  const onSla = async (contractId: number) => {
    const response = await api.getContractSla(contractId);

    setSlaDescription(response.data.description);
  };

  const onSlaChange = (e: { target: { value: SetStateAction<string | undefined>; }; }) => {
    setSlaDescription(e.target.value);
  }

  const handleSave = async () => {
    const response = slaId
      ? await api.updContractSla(slaId, slaDescription || "")
      : await api.setContractSla(contractId, slaDescription || "");

    if (response.data.id) {
      setIsEdit(false);
      setSlaModalOpen(false);
      setSlaIdNew(response.data.id);
    }
  };

  useEffect(() => {
    if (slaId) {
      onSla(slaId);
    }
  }, []);
  
  return (
    <Modal
      className="contract-info-sla-modal"
      open={ isSlaModalOpen }
      onCancel={ () => setSlaModalOpen(false) }
      footer={ null }
      width={ isConfirm ? 320 : 640 }
    >
      {isConfirm ? (
        <div className="sla-mini">
          <div className="sla-mini-title">
            Do you want to save the changes you made?
          </div>

          <div className="sla-mini-buttons">
            <Button
              type="primary"
              className="attach-button"
              onClick={ handleSave }
            >
              Save
            </Button>
            <Button
              className="attach-button"
              onClick={ () => setIsConfirm(false) }
            >
              Exit
            </Button>
          </div>
        </div>
      ) : (
        <div className="sla-modal">
          <div className="sla-header">
            <div className="sla-header-title">
              Service Level Agreement
            </div>

            <img
              alt=""
              src={ editBlue }
              className="sla-header-img"
              onClick={ () => setIsEdit(!isEdit) }
            />
          </div>

          {isEdit ? (
            <div>
              <div className="textarea-wrapper">
                <Input.TextArea
                  rows={ 18 }
                  placeholder="Please enter Service Level Agreement"
                  onChange={ onSlaChange }
                  value={ slaDescription }
                />
              </div>

              <div className="sla-footer">
                <Button
                  type="primary"
                  className="attach-button"
                  onClick={ () => setIsConfirm(true) }
                  disabled={ !slaDescription }
                >
                  Save
                </Button>
              </div>
            </div>
          ) : (
            <div className="content">
              <span>
              {slaDescription || (
                <div className="content-empty">
                  Please enter Service Level Agreement
                </div>
              )}
              </span>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};

export default ContractInfoSlaModal;
